export const getLocalStorageItem = (key: string) => {
  try {
    if (typeof window !== "undefined") {
      return localStorage.getItem(key);
    }
  } catch (err) {
    console.error("Failed to access localStorage:", err);
  }

  return null;
};
