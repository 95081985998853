const envConfig = {
  imgStoreUrl: process.env.NEXT_PUBLIC_IMG_STORE_URL as string,
  publicServerUrl: process.env.NEXT_PUBLIC_API_URL as string,
  publicQraphqlUrl: process.env.NEXT_PUBLIC_API_GRAPHQL_URL as string,
  clientUrl: process.env.NEXT_PUBLIC_CLIENT_URL as string,
  nodeEnv: process.env.NEXT_PUBLIC_NODE_ENV as string,
  appEnv: process.env.NEXT_PUBLIC_APP_ENV as string,
};

export default envConfig;
