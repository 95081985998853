"use client";

import Link from "next/link";

import { useTheme } from "@/components/providers/ThemeProvider";
import WentWrongError from "@/components/shared/WentWrongError";

type ErrorProps = {
  error: Error;
  reset: () => void;
};

export default function Error({ error, reset }: ErrorProps) {
  const { darkMode } = useTheme();

  return (
    <div className="flex flex-col items-center justify-center min-h-screen py-10 text-center">
      <Link href={`/`} className="mb-10" aria-label="Go to the homepage">
        <img
          src={!darkMode ? "/deshabhimanilogo.svg" : "/deshabhimanilogo-white.svg"}
          alt="Deshabhimani logo"
          className="w-[200px] h-[38px] lg:w-[240px] lg:h-[45px] object-cover"
        />
      </Link>
      <WentWrongError error={error} reset={reset} />
    </div>
  );
}
