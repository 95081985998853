import { IoReload } from "react-icons/io5";

import { Typography } from "./Typography";
import envConfig from "@/config/envConfig";

interface WentWrongErrorProps {
  error: Error;
  reset: () => void;
}

export default function WentWrongError({ reset, error }: WentWrongErrorProps) {
  return (
    <div>
      <img src="/images/went-wrong.png" alt="fallback error png" className="w-[300px] mx-auto" />
      <div className="max-w-[510px] mx-auto mt-3">
        <Typography className="" variant="h3">
          Oops, that&#39;s our bad
        </Typography>
        <Typography variant="body1" className="mt-2">
          We&#39;re not exactly sure what happened, but something went wrong. If you need immediate
          help, please <span className="underline">let us know</span>
        </Typography>
        <button
          onClick={() => reset()}
          className="flex items-center gap-2 px-3 py-[6px] border mx-auto rounded font-medium mt-4 text-[15px] transition-all hover:bg-[#f1f1f1]"
          aria-label="Try Again"
        >
          <IoReload />
          Try again
        </button>
      </div>

      {envConfig?.appEnv === "development" && error && (
        <div className="max-w-[900px] mx-auto mt-5">
          <span className="block text-red-700 font-medium text-[15px]">{error?.message}</span>
        </div>
      )}
    </div>
  );
}
