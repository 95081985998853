import { CSSProperties, ReactNode } from "react";

interface TypographyProps {
  variant?:
    | "h1"
    | "h2"
    | "h3"
    | "h4"
    | "h5"
    | "h6"
    | "body1"
    | "body2"
    | "body3"
    | "subtitle1"
    | "subtitle2"
    | "label";
  color?: string;
  className?: string;
  children?: ReactNode;
  language?: "en" | "ml";
  style?: CSSProperties;
}

const elementMapping: {
  [key in NonNullable<TypographyProps["variant"]>]: keyof JSX.IntrinsicElements;
} = {
  h1: "h1",
  h2: "h2",
  h3: "h3",
  h4: "h4",
  h5: "h5",
  h6: "h6",
  subtitle1: "h6",
  subtitle2: "h6",
  body1: "p",
  body2: "p",
  body3: "p",
  label: "p",
};

export const Typography = ({
  variant = "body1", // don't change default
  color,
  className,
  language = "en",
  children,
  style,
  ...props
}: TypographyProps) => {
  const Component = elementMapping[variant] as keyof JSX.IntrinsicElements;

  const fontFamilies: { [key in NonNullable<TypographyProps["language"]>]: string } = {
    en: "font-montserrat",
    ml: "font-anek",
  };

  // TODO:
  // Need to update font-size, line-height and font-weight
  const variants: { [key in NonNullable<TypographyProps["variant"]>]: string } = {
    h1: "text-[27px] md:text-[29px] leading-[1.3] md:leading-[1.3] font-[600]",
    h2: "text-[24px] md:text-[26px] leading-[1.3] md:leading-[1.3] font-[600]",
    h3: "text-[22px] md:text-[24px] leading-[1.3] md:leading-[1.3] font-[600]",
    h4: "text-[20px] md:text-xl font-[600] leading-[1.3] md:leading-[1.3]",
    h5: "text-[18px] md:text-lg font-[600] leading-[1.3] md:leading-[1.3]",
    h6: "text-[16px] md:text-base font-[600] leading-[1.3] md:leading-[1.3]",
    subtitle1: "text-[17px] md:text-lg font-medium leading-[1.3] md:leading-[1.3]",
    subtitle2: "text-[15px] md:text-base font-medium leading-[1.3] md:leading-[1.3]",
    body1: "text-base leading-[26px] md:leading-[26px]",
    body2: "text-[14px] md:text-sm leading-[1.5]",
    body3: "text-xs leading-[1.5]",
    label: "text-[15px] md:text-base lg:text-[13px] uppercase leading-[24px]",
  };

  const typographyClasses = ` ${!color ? " text-[#36454F] dark:text-white " : " "}  ${
    variants[variant]
  } ${fontFamilies[language]} ${className}`;

  return (
    <Component
      style={{ color: color ? color : "", ...(style || {}) }}
      className={typographyClasses}
      {...props}
    >
      {children}
    </Component>
  );
};
